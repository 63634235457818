<template>
  <v-container
    id="supplier-detail"
    fluid
    tag="section"
  >
    <template>
      <contact-detail
        ref="contactDetailRef"
      />
    </template>
    <v-row justify="center">
      <v-col
        cols="12"
        md="7"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Informations société {{ supplier.raisonSociale }}
            </div>
          </template>
          <v-card-text class="text-center">
            <v-simple-table
              id="table-supplier-detail"
            >
                <tbody>
                  <tr
                    v-for="item in itemsSupplier"
                    :key="item.name"
                  >
                    <td
                      class="text-left"
                      width="1%"
                    >
                      <v-icon>{{ item.icon }}</v-icon>
                    </td>
                    <th
                      class="text-left"
                      width="25%"
                    >
                      {{ item.title }}
                    </th>
                    <td
                      class="text-left"
                      v-html="item.value"
                    ></td>
                  </tr>
                </tbody>
            </v-simple-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="5"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Actions
            </div>
          </template>
          <v-card-text class="text-center">
            <v-btn
              color="#00838f"
              rounded
              class="ma-2"
              @click="downloadAttestationVigilance()"
              :disabled="downloadAttestationVigilanceDisabled || !supplier.lienAttestationVigilance"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              Télécharger l'attestation de vigilance
            </v-btn>
            <v-btn
              color="#00838f"
              rounded
              class="ma-2"
              @click="downloadKbis()"
              :disabled="downloadKbisDisabled || !supplier.lienKbis"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              Télécharger le KBIS
            </v-btn>
            <v-btn
              color="blue"
              rounded
              class="ma-2"
              :to="{ name: 'ModifierSousTraitant', params: { id: supplier.id } }"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              Modifier
            </v-btn>
            <v-btn
              color="red darken-1"
              rounded
              class="ma-2"
              @click="dialogDelete = true;"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              Supprimer
            </v-btn>
          </v-card-text>
        </base-material-card>

        <base-material-card
          color="primary"
          title="Contacts"
        >
          <v-row>
            <v-col
              cols="12"
              v-for="contact in supplier.contacts"
              :key="contact.id"
            >
              <v-card
                elevation="2"
                class="px-3 py-2 ma-1"
                @click="$refs.contactDetailRef.show(contact, supplier)"
              >
                <v-card-title
                  class="mb-1"
                >
                  {{ contact.sexe ? titreItems.find(item => item.sexe === contact.sexe).titre : '' }} {{ contact.prenom }} {{ contact.nom }}
                </v-card-title>
                <v-card-text
                  class="contact-email-phone"
                >
                  <p>
                    <v-chip
                      class="mr-2"
                      v-for="typeContact of displayTypesContact(contact.types)"
                      :key="typeContact"
                      input-value="false"
                      small
                    >
                      {{ typeContact }}
                    </v-chip>
                  </p>
                  <p v-if="contact.telephonePro">
                    <span><v-icon class="mr-2">mdi-phone</v-icon> {{ contact.telephonePro }}</span>
                  </p>
                  <p v-if="contact.email">
                    <v-icon class="mr-2">mdi-email</v-icon> {{ contact.email }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
            class="pa-6"
              cols="12"
              md="4"
            >
              <v-btn
                id="btnAjouterContact"
                small
                color="gray"
                @click="$refs.contactDetailRef.create(supplier, 'SOUSTRAITANT')"
              >
                <v-icon
                  class="mr-2"
                  dark
                >
                  mdi-plus-circle
                </v-icon>
                Ajouter un contact
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <template>
      <v-dialog
        v-model="dialogDelete"
        max-width="600px"
      >
        <v-card>
          <v-card-title
            class="headline"
          >
            Confirmer la suppression de ce sous-traitant ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="red darken-1"
              @click="dialogSubmit = false"
            >
              Annuler
            </v-btn>
            <v-btn
              color="green darken-1"
              @click="deleteSupplier()"
            >
              Confirmer la suppression
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {

    components: {
      ContactDetail: () => import('../../component/ContactDetail'),
    },

    data () {
      return {
        dialogDelete: false,
        downloadAttestationVigilanceDisabled: false,
        downloadKbisDisabled: false,
        search: '',
        supplier: {
          raisonSociale: '',
          formeJuridique: '',
          email: '',
          telephone: '',
          iban: '',
          swift: '',
          tvaIntra: '',
          siren: '',
          siret: '',
          adresse: {},
        },
        itemsSupplier: [],
        titreItems: [
          {
            titre: 'M.',
            sexe: 'm',
          },
          {
            titre: 'Mme',
            sexe: 'f',
          },
        ],
      }
    },

    created () {
      this.axios.get('/entreprisesSousTraitantes/' + this.$route.params.id)
        .then((res) => {
          this.supplier = res.data;

          this.itemsSupplier = [
            {
              icon: 'mdi-office-building',
              title: 'Raison Sociale',
              value: this.supplier.raisonSociale,
            },
            {
              icon: 'mdi-barcode-scan',
              title: 'Forme juridique',
              value: this.supplier.formeJuridique,
            },
            {
              icon: 'mdi-phone',
              title: 'Téléphone',
              value: this.supplier.telephone ? this.formatPhone(this.supplier.telephone) : '',
            },
            {
              icon: 'mdi-at',
              title: 'E-mail',
              value: this.supplier.email ?? '',
            },
            {
              icon: 'mdi-map-marker-outline',
              title: 'Adresse',
              value: this.supplier.adresse ? this.formatAdresse(this.supplier.adresse) : '',
            },
            {
              icon: 'mdi-ticket-confirmation-outline',
              title: 'N° SIREN',
              value: this.supplier.siren ?? '',
            },
            {
              icon: 'mdi-ticket-confirmation-outline',
              title: 'N° SIRET',
              value: this.supplier.siret ?? '',
            },
            {
              icon: 'mdi-ticket-confirmation-outline',
              title: 'N° TVA Intra.',
              value: this.supplier.tvaIntra ?? '',
            },
            {
              icon: 'mdi-file-document-outline',
              title: 'Attestation de vigilance',
              value: this.supplier.lienAttestationVigilance ? 'Reçue' : 'Non reçue',
            },
            {
              icon: 'mdi-bank',
              title: 'IBAN',
              value: this.supplier.iban ?? '',
            },
            {
              icon: 'mdi-bank',
              title: 'SWIFT',
              value: this.supplier.swift ?? '',
            },
          ];
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération du sous-traitant', type: 'warn' });
        });
    },

    methods: {

      deleteSupplier() {
        this.axios.delete('/entreprisesSousTraitantes/' + this.supplier.id)
          .then((res) => {
            this.setSnack({ text: 'Le sous-traitant a bien été supprimé', type: 'success' });
            this.$router.push({ name: 'SousTraitants' })
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la suppression du sous-traitant', type: 'warn' });
          });
        this.dialogDelete = false;
      },

      downloadAttestationVigilance() {
        this.downloadAttestationDisabled = true;
        this.axios.get('/entreprisesSousTraitantes/' + this.supplier.id + '/downloadAttestationVigilance', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'AttestationVigilance_' + this.supplier.raisonSociale + '_' + this.supplier.siren + '.pdf');
            document.body.appendChild(link);
            link.click();
            this.downloadAttestationDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de l\'attestation de vigilance du sous-traitant', type: 'warn' });
            this.downloadAttestationDisabled = false;
          });
      },

      downloadKbis() {
        this.downloadKbisDisabled = true;
        this.axios.get('/entreprisesSousTraitantes/' + this.supplier.id + '/downloadKbis', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'KBIS_' + this.supplier.raisonSociale + '_' + this.supplier.siren + '.pdf');
            document.body.appendChild(link);
            link.click();
            this.downloadKbisDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement du KBIS du sous-traitant', type: 'warn' });
            this.downloadKbisDisabled = false;
          });
      },

      displayTypesContact(types) {
        const values = {
          ADMINISTRATIF: 'Contact administratif',
          COMMERCIAL: 'Contact commercial',
          FACTURATION: 'Contact facturation',
        };

        return types.map(type => values[type]);
      },

      rowClick(item, row) {
        this.$router.push({ name: 'FormateurDetail', params: { id: item.id } });
      },

      formatPhone(number) {
        return number.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
      },

      formatAdresse(adresse) {
        let formattedAdresse = '';
        formattedAdresse += adresse.adressePostale ? adresse.adressePostale + '<br>' : '';
        formattedAdresse += adresse.codePostal ? adresse.codePostal + ' ' : '';
        formattedAdresse += adresse.ville ?? '';
        formattedAdresse += adresse.pays ? ', ' + adresse.pays + ' ' : '';
        return formattedAdresse;
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>
  #supplier-detail >>> .text-center {
    text-align: center;
  }

  #supplier-detail >>> #table-supplier-detail th,
  #supplier-detail >>> #table-supplier-detail td {
    font-size: 1rem;
  }

  #supplier-detail >>> .table-speakers tbody td:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  #supplier-detail >>> .table-speakers tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    transition: background-color 0.1s;
  }

  #supplier-detail >>> .table-speakers tbody tr:not(.v-row-group__header):hover {
    background-color: #f8f8f8;
    font-weight: 400 !important;
  }

  #supplier-detail >>> .badge-chips-small {
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 0.7rem;
    border: solid 1px black;
  }
</style>
